import { Box, Grid, } from "@mui/material";
import React, { useState } from "react";
import "./home.css";
import "../../node_modules/slick-carousel/slick/slick.css";
import img1 from "../img/img1.png";
import img2 from "../img/img2.png";
import img3 from "../img/img3.png";
import img4 from "../img/img4.png";
import img5 from "../img/img5.png";
import img6 from "../img/img6.png";
import img7 from "../img/img7.png";
import img8 from "../img/img8.png";
import img9 from "../img/img9.png";
import icon1 from "../img/icon1.png";
import icon2 from "../img/icon2.png";
import icon3 from "../img/icon3.png";
import icon4 from "../img/icon4.png";
import icon5 from "../img/icon5.png";
import icon6 from "../img/icon6.png";
import icon7 from "../img/icon7.png";
import icon8 from "../img/icon8.png";
import icon9 from "../img/icon9.png";
import icon10 from "../img/icon10.png";
import icon11 from "../img/icon11.png";
import icon12 from "../img/icon12.png";
import card_logo from "../img/card-logo.jpg";
import { Masonry } from "@mui/lab";
import Slider from "react-slick";
import Card from "../components/Card";
import RatingCard from "../components/RatingCard";

const heights = [img4, img5, img6, img7, img8, img9];

export default function Home() {
  const [value, setValue] = useState(2);
  const settings = {
    infinite: true,
    arrows: false,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <div>
      <section className="hero scroll-margin" id="home">
        <div className="container-fluid">
          <div className="text-center content-center">
            <h5 className="hero-h5 m-0">
              Hi, I am <span className="orange">Akshay Parmar</span> 👋
            </h5>
            <h1 className="hero-h1">App Developer & Web Developer</h1>
            <p className="hero-text m-0">
              I design and code beautifully simple things, and I love what I do.
            </p>
          </div>
        </div>
      </section>
      <section className="client">
        <div className="container-fluid">
          <Grid
            container
            rowSpacing={{ xs: 2, md: 3, xl: 3 }}
            columnSpacing={{ md: 3, xl: 3 }}
          >
            <Grid item xs={12} md={6} lg={4}>
              <Card
                img={img1}
                client={"5+"}
                experience={"Years of Experience"}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Card
                img={img2}
                client={"100+"}
                experience={"Completed Projects"}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Card img={img3} client={"50+"} experience={"Happy Clients"} />
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="who-i-am scroll-margin" id="about">
        <div className="container-fluid">
          <div className="who-i-am-card">
            <div className="who-i-am-btn">
              <button>Who I Am</button>
            </div>
            <h2 className="m-0">About Me</h2>
            <p className="m-0">
              Greetings! I'm Akshay Parmar, a creative designer and developer with a deep-seated enthusiasm for crafting sleek web and mobile applications that offer intuitive user experiences. My joy stems from the transformation of conceptual ideas into tangible, innovative solutions. I'm an inquisitive learner, continually exploring novel skills, tools, and concepts. Alongside my solo full-stack projects, I've collaborated with dynamic creative teams, engaging in daily stand-ups, seamless communication, version control, and effective project management.
            </p>
            <div className="hire-me-btn">
              <button>Hire Me</button>
            </div>
          </div>
        </div>
      </section>
      <section className="my-skills scroll-margin" id="skills">
        <div className="container-fluid">
          <Grid container columnSpacing={10}>
            <Grid item lg={6} xs={12}>
              <div>
                <button className="my-skills-btn">My Skills</button>
                <h3 className="m-0">What My Programming Skills Included?</h3>
                <p>
                  I develop simple, intuitive and responsive user interface that
                  helps users get things done with less effort and time with
                  those technologies.
                </p>
              </div>
              <button className="hire-me-btn">Hire Me</button>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className="d-flex flex-row justify-content-center align-items-center my-5">
                <Grid
                  container
                  columnSpacing={{ xs: -4, sm: 3, lg: -20, xl: -20 }}
                  rowSpacing={{ xs: 3, sm: 4, xl: 3 }}
                >
                  <Grid item xs={4} md={2} xl={2}>
                    <img src={icon1} alt="" className="img-fluid" />
                  </Grid>
                  <Grid item xs={4} md={2} xl={2}>
                    <img src={icon2} alt="" className="img-fluid" />
                  </Grid>
                  <Grid item xs={4} md={2} xl={2}>
                    <img src={icon3} alt="" className="img-fluid" />
                  </Grid>
                  <Grid item xs={4} md={2} xl={2}>
                    <img src={icon4} alt="" className="img-fluid" />
                  </Grid>
                  <Grid item xs={4} md={2} xl={2}>
                    <img src={icon5} alt="" className="img-fluid" />
                  </Grid>
                  <Grid item xs={4} md={2} xl={2}>
                    <img src={icon6} alt="" className="img-fluid" />
                  </Grid>
                  <Grid item xs={4} md={2} xl={2}>
                    <img src={icon7} alt="" className="img-fluid" />
                  </Grid>
                  <Grid item xs={4} md={2} xl={2}>
                    <img src={icon8} alt="" className="img-fluid" />
                  </Grid>
                  <Grid item xs={4} md={2} xl={2}>
                    <img src={icon9} alt="" className="img-fluid" />
                  </Grid>
                  <Grid item xs={4} md={2} xl={2}>
                    <img src={icon10} alt="" className="img-fluid" />
                  </Grid>
                  <Grid item xs={4} md={2} xl={2}>
                    <img src={icon11} alt="" className="img-fluid" />
                  </Grid>
                  <Grid item xs={4} md={2} xl={2}>
                    <img src={icon12} alt="" className="img-fluid" />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="my-works scroll-margin" id="portfolio">
        <div className="container-fluid">
          <div className="text-center">
            <button className="my-works-btn">My Works</button>
            <h5 className="m-0">Featured Works</h5>
            <ul className="my-works-list">
              <li>
                <a href="javascriptVoid(0)">All</a>
              </li>
              <li>
                <a href="javascriptVoid(0)">Mobile App</a>
              </li>
              <li>
                <a href="javascriptVoid(0)">Website Design</a>
              </li>
              <li>
                <a href="javascriptVoid(0)">Website Development</a>
              </li>
            </ul>
            <div className="my-work-demo">
              <Box>
                <Masonry
                  columns={{ xs: 1, sm: 3, xl: 3 }}
                  xs={{ margin: 0 }}
                  spacing={{ xs: 2, xl: 6, sm: 3 }}
                >
                  {heights.map((height, index) => {
                    return (
                      <div key={index}>
                        {/* <div style={{backgroundImage: `url(${height})`, height: heigh, backgroundSize: 'cover'}}></div> */}
                        <img src={height} alt="" className="img-fluid" />
                      </div>
                    );
                  })}
                </Masonry>
              </Box>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="testimonials scroll-margin" id="testimonials">
        <div className="container-fluid">
          <div className="text-center">
            <button className="testimonials-btn">testimonials</button>
            <h6 className="m-0">What customer says</h6>
          </div>
          <Slider {...settings}>
            <RatingCard
              value={value}
              setValue={setValue}
              card_logo={card_logo}
            />
            <RatingCard
              value={value}
              setValue={setValue}
              card_logo={card_logo}
            />
            <RatingCard
              value={value}
              setValue={setValue}
              card_logo={card_logo}
            />
            <RatingCard
              value={value}
              setValue={setValue}
              card_logo={card_logo}
            />
            <RatingCard
              value={value}
              setValue={setValue}
              card_logo={card_logo}
            />
            <RatingCard
              value={value}
              setValue={setValue}
              card_logo={card_logo}
            />
          </Slider>
        </div>
      </section> */}
    </div>
  );
}
