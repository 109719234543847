import "../pages/home.css";
import React from "react";

export default function Card({img, client, experience}) {
  return (
    <div>
      <div className="card">
        <div className="d-flex justify-content-between align-items-center">
          <div className="card-logo">
            <img src={img} alt="" className="img-fluid" />
          </div>
          <div className="exp-no d-flex flex-column text-center">
            <h5 className="m-0">{client}</h5>
            <p className="m-0">{experience}</p>
          </div>
          <div className="more-btn">
            <i className="fa fa-duotone fa-angle-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
}
