import "./header.css";
import React, { useState } from "react";

export default function Header() {
  const [toggleSideBar, setSidebarToggle] = useState(false);

  function openSidebar() {
    setSidebarToggle(!toggleSideBar);
  }

  return (
    <div>
      <header>
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div
              className="overlay"
              onClick={() => {
                openSidebar();
              }}
              style={toggleSideBar ? { right: 0 } : { right: "100%" }}
            ></div>
            <div className="logo">
              <h3 className="m-0">Akshay Parmar</h3>
            </div>
            <div
              className="list"
              style={toggleSideBar ? { right: 0 } : { right: "-200px" }}
            >
              <ul>
                <li>
                  <a href="#home" className="active">
                    Home
                  </a>
                </li>
                <li>
                  <a href="#about">About</a>
                </li>
                <li>
                  <a href="#skills">Skills</a>
                </li>
                <li>
                  <a href="#portfolio">Portfolio</a>
                </li>
                <li>
                  <a href="#testimonials">Testimonials</a>
                </li>
                <li>
                  <a href="#contact">Contact</a>
                </li>
                <li>
                  <div className="hire">
                    <button className="hire-btn">Hire Me</button>
                  </div>
                </li>
              </ul>
            </div>
            <div className="hire">
              <button className="hire-btn">Hire Me</button>
            </div>
            <div className="bras" onClick={() => openSidebar()}>
              <i className="fa fa-solid fa-bars"></i>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
