import "./footer.css";
import React from "react";

export default function Footer() {
  return (
    <div>
      <footer id="contact">
        <div className="container-fluid">
          <div className="start-project">
            <h6 className="m-0">Start a project</h6>
            <p className="m-0">
              Interested in working together? We should queue up a chat. I’ll
              buy the coffee.
            </p>
            <div className="text-center">
              <button className="lets-btn">Let’s do this</button>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div className="footer-title">
                <h3>Akshay Parmar</h3>
                <p>Creative designer & front-end developer</p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <h6>Useful Links</h6>
              <ul className="useful-link">
                <li>
                  <a href="javascriptVoid(0)">Home</a>
                </li>
                <li>
                  <a href="javascriptVoid(0)">About</a>
                </li>
                <li>
                  <a href="javascriptVoid(0)">Skills</a>
                </li>
                <li>
                  <a href="javascriptVoid(0)">Portfolio</a>
                </li>
                <li>
                  <a href="javascriptVoid(0)">Contact</a>
                </li>
              </ul>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <h6>Social Media</h6>
              <ul className="social-media">
                <li>
                  <a href="javascriptVoid(0)">Instagram</a>
                </li>
                <li>
                  <a href="javascriptVoid(0)">linkedin</a>
                </li>
                <li>
                  <a href="javascriptVoid(0)">Dribble</a>
                </li>
                <li>
                  <a href="javascriptVoid(0)">Behance</a>
                </li>
                <li>
                  <a href="javascriptVoid(0)">Github</a>
                </li>
              </ul>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <h6>Newsletter</h6>
              <p className="newsletter-text">
                Enter your email and get notified about news, of.
              </p>
              <div className="email-send">
                <input
                  type="email"
                  className="email"
                  placeholder="Your email address"
                />
                <i class="fa fa-brands fa-telegram fa-beat"></i>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
